.input {
  outline: none;
  font-size: 19px;
  border: none;
  color: darkgrey;
  border-bottom: 1px solid lightgrey;
}

.input::placeholder {
  color: lightgrey;
}
