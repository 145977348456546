@value colors: "../../colors.css";
@value green, red from colors;

.verified {
  color: green;
}

.notVerified {
  color: red;
}
