.wrapper {
  align-items: center;
  border-bottom: 1px solid #cacaca;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
}

.wrapper input[type="text"] {
  margin-right: 8px;
}

.error {
  color: red;
}
