.clickable:hover {
  cursor: pointer;
  font-weight: bold;
}

.label {
  margin-left: 5px;
  padding: 2px 3px;
  font-size: 0.8em;
  color: white;
  border-radius: 5px;
}

.disabled {
  text-align: center;
  background: lightgrey;
}
