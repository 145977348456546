.spinnerWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
}

.wrapper > div {
  margin-bottom: 12px;
}

.topWrapper {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.text {
  margin: auto 3em;
  max-width: 50rem;
  min-width: 20rem;
}

.advice {
  margin-top: 30px;
}

.noChartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
}

.robotsTxtInfo {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.robotsTxtStatus {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rawRobotsTxt {
  display: flex;
  padding: 20px;
  white-space: pre-wrap;
  width: 100%;
}
