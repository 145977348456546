.wrapper {
  background: white;
  height: 200px;
  border-radius: 10px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  border: 1px solid transparent;
}

.wrapper:hover {
  cursor: pointer;
  border: 1px solid darkblue;
}

.title {
  font-size: 1.5em;
  color: darkblue;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-column: 1 / 5;
}

.container {
  width: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.value {
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
