@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

@value colors: "./colors.css";
@value green, primary, red from colors;

body {
  margin: 0;
  padding: 0;
}

body,
button {
  color: darkslategrey;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}

select,
textarea {
  font-family: "Roboto", sans-serif;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 8px;
}

input[type="file"] {
  display: none;
}

select {
  background-color: transparent;
}

a,
button:focus,
input:focus,
select:focus,
textarea :focus {
  outline: 0;
}

a {
  color: darkslategrey;
  text-decoration: none;
}

button {
  cursor: pointer;
  display: inline-block;
}

button:disabled {
  background-color: lightgray;
  box-shadow: 0 0 1px lightgray;
  color: white;
}

button:disabled:hover {
  box-shadow: 0 0 1px lightgray;
}

/* Deprecated */
.red {
  color: red;
}

/* Deprecated */
.green {
  color: green;
}
