.wrapper {
  display: flex;
  height: 60px;
  text-align: center;
}

.value {
  color: white;
  display: flex;
  font-size: 2em;
  justify-content: center;
  min-width: 2em;
  padding: 0.2em;
}

.label {
  padding: 0.8em;
  margin: auto;
}
