.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12vh;
}

.container p {
  margin-top: 0;
}

.container button {
  margin: auto 12px;
}
