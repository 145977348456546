@value colors: "../../colors.css";
@value primary from colors;

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  max-width: 1300px;
}

.currentKeywords {
  flex: 1;
  margin: 12px;
  width: 100%;
}

.newKeywords {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  /*min-height: 100%;*/
  width: 100%;
}

.newKeywordsHeader {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
}

.inputWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.keywordsList {
  align-content: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  overflow-y: scroll;
  min-height: 200px;
}

.newKeyword {
  background: primary;
  color: whitesmoke;
  padding: 8px;
  margin: 6px;
}

.inputKeyword {
  display: grid;
  justify-content: stretch;
  align-items: center;
}

.input {
  outline: none;
  border: none;
  border-bottom: 1px solid lightgrey;
}

.inputUpload {
  display: grid;
  justify-content: stretch;
  align-items: center;
}

.disabledUpdateButton {
  background: grey;
  color: lightgrey;
}
