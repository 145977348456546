.wrapper {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  color: darkgrey;
}

.container {
  display: grid;
  grid-gap: 20px;
  margin: auto;
}

.containerList {
  display: grid;
  grid-gap: 20px;
  overflow-y: auto;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-rows: repeat(auto-fill, 200px);
}

.loadingWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
