.wrapper {
  overflow-y: auto;
  padding: 20px;
  color: darkgrey;
}

.container {
  display: grid;
  grid-gap: 20px;
  margin: auto;
}

.contentContainer {
  height: 300px;
  min-height: 300px;
  min-width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.chartContainer {
  background: white;
  border-radius: 10px;
  width: calc(75% - 10px);
  margin: 0 10px 0 0;
}

.tableContainer {
  height: auto;
}

.loadingWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
