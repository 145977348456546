.wrapper {
  display: flex;
  flex-direction: column;
}

.wrapper label {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 8px;
}

.wrapper label > input {
  margin-left: 12px;
}
