.content {
  align-items: center;
  display: flex;
  justify-content: center;
}

.profilePicture {
  box-shadow: 0 0 12px lightgray;
  border-radius: 50%;
  max-height: 64px;
  max-width: 64px;
  margin: 20px 60px;
  padding: 20px;
}
