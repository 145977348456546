@value colors: "../../colors.css";
@value primary from colors;

.defaultWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 12px auto;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  margin: 12px auto;
}

.finalizationWrapper {
  background: white;
  border-top: 1px solid lightgrey;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: sticky;
  padding: 12px 0;
}

.finalizationWrapper label {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.finalizationWrapper label > input {
  margin: auto 24px auto 6px;
  max-width: 60px;
}
