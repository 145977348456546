.wrapper {
  margin: 12px;
}

.header {
  display: flex;
}

.header > div {
  align-items: center;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: space-evenly;
  padding-bottom: 6px;
}

.quotesWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.quotesWrapper > div {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.quotesList {
  display: flex;
  flex-direction: column;
}

.quotesList > div > div {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: center;
}

.quoteRow {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.quoteRow:hover {
  background: whitesmoke;
}
