.wrapper {
  display: flex;
  flex-flow: column nowrap;
  margin: 10px;
}

.clickable:hover {
  cursor: pointer;
  text-decoration: line-through;
}
