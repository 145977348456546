@value colors: "../../colors.css";
@value green, red from colors;

.authorized {
  color: green;
}

.unauthorized {
  color: red;
}
