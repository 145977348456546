.wrapper {
  display: flex;
  flex-flow: column;
  margin: 20px;
}

.contentContainer {
  display: flex;
  flex-flow: row wrap;
  height: 300px;
  margin-bottom: 20px;
  min-height: 300px;
}

.chartContainer {
  width: calc(75% - 10px);
}

.tableContainer {
  height: auto;
}

.loadingWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
