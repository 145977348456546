.container {
  width: 75%;
  background: white;
  border-radius: 10px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.header {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 20px 0 0 0;
}

.center {
  padding: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 10px;
}

.inputContainer {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-gap: 10px;
}

.inputColumn {
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: end;
}

.footer {
  display: grid;
  align-items: center;
  justify-items: end;
  padding: 0 20px 20px 20px;
}

.startCrawl {
  background: green;
  color: grey;
  border: none;
  border-radius: 3px;
  padding: 15px 100px;
}

.keywordsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.listContainerWithKeywords {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;
  position: relative;
}

.listContainerWithoutKeywords {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: 10px;
  position: relative;
}

.noKeywordsList {
  border: 1px solid darkblue;
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 10px;
}

.keywordsList {
  border: 1px solid darkblue;
  border-radius: 10px;
  overflow-y: scroll;
  max-height: 135px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.inputKeyword {
  grid-area: inputKeyword;
  display: grid;
  justify-content: stretch;
  align-items: center;
}

.wrapperKeywordComponent {
  background: lightblue;
  color: darkblue;
  height: min-content;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
}

.dropzoneContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
}

.spaceForErrorMessage {
}

.uploadError {
  position: absolute;
  bottom: -20px;
}

.dropzoneComponent {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: lightblue;
  color: darkblue;
  border: 2px dashed darkblue;
  cursor: pointer;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 20px;
}

.startCrawlDisabled {
  background: lightgrey;
}

.eraseButton {
  color: red;
}

.eraseButton :hover {
  cursor: pointer;
}
