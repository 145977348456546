@value colors: "../../../colors.css";
@value primary, secondary, complementary, lightgrey from colors;

.container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border: 1px solid #ddd;
  box-shadow: 0 0 1px #cacaca;
}

.container:hover {
  box-shadow: 0 0 3px #cacaca;
}

.header {
  background: dimgrey;
  align-items: center;
  border-bottom: 1px solid #ddd;
  color: whitesmoke;
  display: flex;
  justify-content: flex-start;
}

.header i {
  margin-left: 0.5em;
}

.header h4 {
  margin: 0.5em;
}

.content {
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  flex-grow: 1;
  padding: 0 1em 1em 1em;
}

/* Quick CSS */
.content > form {
  margin-top: 1em;
}
