.defaultWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.wrapper {
  background-color: whitesmoke;
  min-height: 100vh;
}

.container {
  padding: 1rem;
}

.gridItem {
  min-width: 360px;
  max-width: 360px;
}
