@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@value colors: "./colors.css";
@value green, primary, red from colors;

body {
  margin: 0;
  padding: 0;
}

body,
button {
  color: darkslategrey;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}

select,
textarea {
  font-family: "Roboto", sans-serif;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 8px;
}

input[type="file"] {
  display: none;
}

select {
  background-color: transparent;
}

a,
button:focus,
input:focus,
select:focus,
textarea :focus {
  outline: 0;
}

a {
  color: darkslategrey;
  text-decoration: none;
}

button {
  cursor: pointer;
  display: inline-block;
}

button:disabled {
  background-color: lightgray;
  box-shadow: 0 0 1px lightgray;
  color: white;
}

button:disabled:hover {
  box-shadow: 0 0 1px lightgray;
}

/* Deprecated */
.red {
  color: red;
}

/* Deprecated */
.green {
  color: green;
}

.Login_container__300p1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.Login_container__300p1 button {
  margin: 1rem;
}

.Login_logo__1Sv5Y {
  margin-bottom: 12px;
  max-width: 180px;
}

.Login_loginError__Hwo_U {
  color: red;
}

.Login_version__3HKVK {
  bottom: 12px;
  position: absolute;
  right: 12px;
}

.Spinner_spinner__1XGpJ {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.Spinner_spinner__1XGpJ:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #634c72;
  border-color: #634c72 transparent #634c72 transparent;
  -webkit-animation: Spinner_spinner__1XGpJ 1.2s linear infinite;
          animation: Spinner_spinner__1XGpJ 1.2s linear infinite;
}

@-webkit-keyframes Spinner_spinner__1XGpJ {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes Spinner_spinner__1XGpJ {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.OptimizationView_spinnerWrapper__3aLoV {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.OptimizationView_wrapper__1f_hN {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
}

.OptimizationView_wrapper__1f_hN > div {
  margin-bottom: 12px;
}

.OptimizationView_topWrapper__2EM9s {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.OptimizationView_text__rq_I7 {
  margin: auto 3em;
  max-width: 50rem;
  min-width: 20rem;
}

.OptimizationView_advice__2Tq6l {
  margin-top: 30px;
}

.OptimizationView_noChartContainer__3AgWl {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
}

.OptimizationView_robotsTxtInfo__kOJQU {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.OptimizationView_robotsTxtStatus__3p534 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.OptimizationView_rawRobotsTxt__1ehb8 {
  display: flex;
  padding: 20px;
  white-space: pre-wrap;
  width: 100%;
}



.Tile_container__2Tiv9 {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border: 1px solid #ddd;
  box-shadow: 0 0 1px #cacaca;
}

.Tile_container__2Tiv9:hover {
  box-shadow: 0 0 3px #cacaca;
}

.Tile_header__ewjI1 {
  background: dimgrey;
  align-items: center;
  border-bottom: 1px solid #ddd;
  color: whitesmoke;
  display: flex;
  justify-content: flex-start;
}

.Tile_header__ewjI1 i {
  margin-left: 0.5em;
}

.Tile_header__ewjI1 h4 {
  margin: 0.5em;
}

.Tile_content__1LB3t {
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  flex-grow: 1;
  padding: 0 1em 1em 1em;
}

/* Quick CSS */
.Tile_content__1LB3t > form {
  margin-top: 1em;
}

.UrlForm_wrapper__kRzLU {
  align-items: center;
  border-bottom: 1px solid #cacaca;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
}

.UrlForm_wrapper__kRzLU input[type="text"] {
  margin-right: 8px;
}

.UrlForm_error__2U57g {
  color: red;
}

.Label_label__3qKam {
  padding: 1px 6px;
  border-radius: 3px;
  font-size: 12px;
  min-width: 60px;
  text-align: center;
}

.Label_red__1oT4m {
  background-color: red;
  color: whitesmoke;
}

.Label_orange__BOJCu {
  background-color: orange;
  color: whitesmoke;
}

.Label_green__3-VGw {
  background-color: green;
  color: whitesmoke;
}

.Label_black__zb-_p {
  background-color: grey;
  color: whitesmoke;
}

.Label_default__2-TDP {
  background-color: purple;
  color: whitesmoke;
}

.Row_row__2nIc5 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 12px;
}

.Row_titleColumn__38eNI {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px 0 0;
}

.Row_titleColumn__38eNI > div > span {
  margin-left: 10px;
}

.Row_valueColumn__ZPGz6 {
  border-left: 1px solid lightgrey;
  padding: 0 0 0 20px;
}

.Row_input__EDHRs {
  text-decoration: underline;
  color: darkgrey;
}

.Row_input__EDHRs:hover {
  cursor: pointer;
  color: darkblue;
}

.UrlFinderView_wrapper__27R61 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 16px;
}

.UrlFinderView_wrapper__27R61 > div {
  margin-bottom: 16px;
}

.UrlFinderView_tileContent__ijZzz {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
}

.UrlFinderView_dataContainer__18K8w {
  width: 100%;
}

.UrlFinderView_row__1mXHc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 12px;
  width: 100%;
}

.UrlFinderView_titleColumn__14F1Q {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px 0 0;
}

.UrlFinderView_valueColumn__3eKlq {
  border-left: 1px solid lightgrey;
  padding: 0 0 0 20px;
}

.Architecture_input__1RSuB {
  text-decoration: underline;
  color: darkgrey;
}

.Architecture_input__1RSuB:hover {
  cursor: pointer;
  color: darkblue;
}

.UrlFinder_noUrlWrapper__var6r {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1%;
}

.AuditView_wrapper__2An15 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 1rem;
}

.AuditView_error__C-u64 {
  color: red;
}

.OrderView_wrapper__hBr7n {
  margin: 12px;
}

.OrderView_header__2-BJr {
  display: flex;
}

.OrderView_header__2-BJr > div {
  align-items: center;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: space-evenly;
  padding-bottom: 6px;
}

.OrderView_quotesWrapper__ctZAl {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.OrderView_quotesWrapper__ctZAl > div {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.OrderView_quotesList__35sGp {
  display: flex;
  flex-direction: column;
}

.OrderView_quotesList__35sGp > div > div {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: center;
}

.OrderView_quoteRow__1EwSQ {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.OrderView_quoteRow__1EwSQ:hover {
  background: whitesmoke;
}

/* Normal triggers (<a>, <button>, <input[type="submit"]> ...) */
.src_primaryTrigger__2aqSn {
  background-color: #f24d4b;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 1px #f24d4b;
  color: white;
  cursor: pointer;
  display: inline-block;
  margin: 2px;
  padding: 6px 10px;
}

.src_primaryTrigger__2aqSn:hover {
  box-shadow: 0 0 2px #f24d4b;
}

/* Red triggers (<a>, <button>, <input[type="submit"]> ...) */
.src_cancelTrigger__2wQdm,
.src_deleteTrigger__2r_uw,
.src_forbidTrigger__1MfQE {
  background-color: crimson;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 1px crimson;
  color: white;
  cursor: pointer;
  display: inline-block;
  margin: 2px;
  padding: 6px 10px;
}

.src_cancelTrigger__2wQdm:hover,
.src_deleteTrigger__2r_uw:hover,
.src_forbidTrigger__1MfQE:hover {
  box-shadow: 0 0 2px crimson;
}

/* Green triggers (<a>, <button>, <input[type="submit"]> ...) */
.src_addTrigger__1oKBc,
.src_allowTrigger__3JdO4,
.src_confirmationTrigger__3wfac,
.src_retryTrigger__1Qg33 {
  background-color: #27A49B;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 1px #27A49B;
  color: white;
  cursor: pointer;
  display: inline-block;
  margin: 2px;
  padding: 6px 10px;
}

.src_addTrigger__1oKBc:hover,
.src_allowTrigger__3JdO4:hover,
.src_confirmationTrigger__3wfac:hover,
.src_retryTrigger__1Qg33:hover {
  box-shadow: 0 0 2px #27A49B;
}

/* Disabled triggers (<a>, <button>, <input[type="submit"]> ...) */
.src_disabledTrigger__23Vmw {
  background-color: lightgray;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 1px lightgray;
  color: white;
  cursor: pointer;
  display: inline-block;
  margin: 2px;
  padding: 6px 10px;
}

.src_disabledTrigger__23Vmw:hover {
  box-shadow: 0 0 1px lightgray;
}

.CampaignView_container__3gXjk {
}

.CampaignView_container__3gXjk h1 {
  font-size: 3rem;
}

.CampaignView_container__3gXjk h2 {
  font-size: 2rem;
}

.CampaignView_header__2fb_Q {
  margin: 1rem 2rem;
}

.CampaignView_published__31JFo {
  margin: 3rem 2rem;
}

.CampaignView_configuration__1WgPu {
  margin: 3rem 2rem;
}

.CampaignView_heading__2_34p {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.CampaignView_heading__2_34p h2 {
  margin-right: 1rem;
}

.UsersList_spinnerContainer__112pG {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* The switch - the box around the slider */
.Switch_switch__3H6nH {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}

/* Hide default HTML checkbox */
.Switch_switch__3H6nH input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.Switch_slider__1JgAS {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 36px;
}

.Switch_slider__1JgAS:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .Switch_slider__1JgAS {
  background-color: #f24d4b;
}

input:focus + .Switch_slider__1JgAS {
  box-shadow: 0 0 1px #f24d4b;
}

input:checked + .Switch_slider__1JgAS:before {
  transform: translateX(18px);
}

.NewQuoteTodos_tile__2JcY1 {
  grid-column: 1 / 3;
}

.NewQuoteTodos_header__SccdG {
  font-weight: bold;
  padding: 0 0 6px 0;
}

.NewQuoteTodos_wrapper__2d09g {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.NewQuoteTodos_wrapper__2d09g > div {
  align-items: center;
  border-bottom: 1px solid lightgrey;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.NewQuoteTodos_wrapper__2d09g > div > div {
  flex-basis: 0;
  flex-grow: 1;
}

.NewQuoteTodos_wrapper__2d09g input {
  max-width: 60px;
}

.NewQuoteTodos_enabled__1T9C7 > div > p,
.NewQuoteTodos_disabled__1lw5R > div > p {
  margin: 12px auto;
}

.NewQuoteTodos_resetContainer__3I1M6 {
  display: inline;
  color: lightgrey;
  margin-left: 12px;
}

.NewQuoteTodos_resettable__10OkH {
  color: black !important;
}

.NewQuoteTodos_enabled__1T9C7:hover {
  background: whitesmoke;
}

.NewQuoteTodos_disabled__1lw5R {
  color: lightgrey;
}

.NewQuoteUser_wrapper__z6gvs {
  display: flex;
  flex-direction: column;
}

.NewQuoteUser_wrapper__z6gvs label {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 8px;
}

.NewQuoteUser_wrapper__z6gvs label > input {
  margin-left: 12px;
}

.NewQuote_defaultWrapper__3M9Zm {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 12px auto;
}

.NewQuote_wrapper__3lAbe {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  margin: 12px auto;
}

.NewQuote_finalizationWrapper__2z5RP {
  background: white;
  border-top: 1px solid lightgrey;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  padding: 12px 0;
}

.NewQuote_finalizationWrapper__2z5RP label {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.NewQuote_finalizationWrapper__2z5RP label > input {
  margin: auto 24px auto 6px;
  max-width: 60px;
}

.Error_input__3Yc36 {
  border-bottom: 1px solid red;
}

.Error_message__1dW82 {
  color: red;
}

.Form_input__2xTpV {
  outline: none;
  font-size: 19px;
  border: none;
  color: darkgrey;
  border-bottom: 1px solid lightgrey;
}

.Form_input__2xTpV::-webkit-input-placeholder {
  color: lightgrey;
}

.Form_input__2xTpV::-ms-input-placeholder {
  color: lightgrey;
}

.Form_input__2xTpV::placeholder {
  color: lightgrey;
}

.Add_container__yIMjQ {
  width: 75%;
  background: white;
  border-radius: 10px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.Add_header__2w6we {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 20px 0 0 0;
}

.Add_center__1V9KL {
  padding: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 10px;
}

.Add_inputContainer__30PCE {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-gap: 10px;
}

.Add_inputColumn__uyYZj {
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: end;
}

.Add_footer__1CjG6 {
  display: grid;
  align-items: center;
  justify-items: end;
  padding: 0 20px 20px 20px;
}

.Add_startCrawl__18V59 {
  background: green;
  color: grey;
  border: none;
  border-radius: 3px;
  padding: 15px 100px;
}

.Add_keywordsContainer__A2UaH {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.Add_listContainerWithKeywords__2B-eU {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;
  position: relative;
}

.Add_listContainerWithoutKeywords__1IXpf {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: 10px;
  position: relative;
}

.Add_noKeywordsList__CuLeZ {
  border: 1px solid darkblue;
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 10px;
}

.Add_keywordsList__2Kfwu {
  border: 1px solid darkblue;
  border-radius: 10px;
  overflow-y: scroll;
  max-height: 135px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.Add_inputKeyword__1v3Oy {
  grid-area: inputKeyword;
  display: grid;
  justify-content: stretch;
  align-items: center;
}

.Add_wrapperKeywordComponent__eNNoo {
  background: lightblue;
  color: darkblue;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
}

.Add_dropzoneContainer__1pi1g {
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
}

.Add_spaceForErrorMessage__BOmzR {
}

.Add_uploadError__3nlV3 {
  position: absolute;
  bottom: -20px;
}

.Add_dropzoneComponent__2GApH {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: lightblue;
  color: darkblue;
  border: 2px dashed darkblue;
  cursor: pointer;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 20px;
}

.Add_startCrawlDisabled__1rhKr {
  background: lightgrey;
}

.Add_eraseButton__3Ft-E {
  color: red;
}

.Add_eraseButton__3Ft-E :hover {
  cursor: pointer;
}

.AddKeywords_wrapper__28Lin {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  max-width: 1300px;
}

.AddKeywords_currentKeywords__3RwWQ {
  flex: 1 1;
  margin: 12px;
  width: 100%;
}

.AddKeywords_newKeywords__3T3Uw {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  /*min-height: 100%;*/
  width: 100%;
}

.AddKeywords_newKeywordsHeader__NB0e3 {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
}

.AddKeywords_inputWrapper__BIXgw {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.AddKeywords_keywordsList__S348l {
  align-content: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  overflow-y: scroll;
  min-height: 200px;
}

.AddKeywords_newKeyword__VN_Jt {
  background: #f24d4b;
  color: whitesmoke;
  padding: 8px;
  margin: 6px;
}

.AddKeywords_inputKeyword__28SJh {
  display: grid;
  justify-content: stretch;
  align-items: center;
}

.AddKeywords_input__5FMEz {
  outline: none;
  border: none;
  border-bottom: 1px solid lightgrey;
}

.AddKeywords_inputUpload__31NY7 {
  display: grid;
  justify-content: stretch;
  align-items: center;
}

.AddKeywords_disabledUpdateButton__PvDPf {
  background: grey;
  color: lightgrey;
}

.Metric_wrapper__3_4ck {
  display: flex;
  height: 60px;
  text-align: center;
}

.Metric_value__1luFh {
  color: white;
  display: flex;
  font-size: 2em;
  justify-content: center;
  min-width: 2em;
  padding: 0.2em;
}

.Metric_label__2hnOh {
  padding: 0.8em;
  margin: auto;
}

.Header_wrapper__NDkRw {
  width: 100%;
  margin-bottom: 20px;
}

.Header_topContainer__1czEl {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Header_rightContainer__30WFd {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.Header_rightContainer__30WFd > div {
  margin: auto 12px;
}

.Header_metricsContainer__3SfKz {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}

.Filter_wrapper__3f1tc {
  display: flex;
  flex-flow: column nowrap;
  margin: 10px;
}

.Filter_clickable__1Wk-o:hover {
  cursor: pointer;
  text-decoration: line-through;
}

.keywords_clickable__13m_m:hover {
  cursor: pointer;
  font-weight: bold;
}

.keywords_label__1z06c {
  margin-left: 5px;
  padding: 2px 3px;
  font-size: 0.8em;
  color: white;
  border-radius: 5px;
}

.keywords_disabled__2HykR {
  text-align: center;
  background: lightgrey;
}

.competitors_clickable__2SZ-n:hover {
  cursor: pointer;
  font-weight: bold;
}

.competitors_label__wpGVt {
  margin-left: 5px;
  padding: 2px 3px;
  font-size: 0.8em;
  color: white;
  border-radius: 5px;
}

.competitors_disabled__2sgzW {
  text-align: center;
  background: lightgrey;
}

.Competitors_wrapper__zRKls {
  overflow-y: auto;
  padding: 20px;
  color: darkgrey;
}

.Competitors_container__5Jfm7 {
  display: grid;
  grid-gap: 20px;
  margin: auto;
}

.Competitors_contentContainer__1kvVs {
  height: 300px;
  min-height: 300px;
  min-width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.Competitors_chartContainer__jJW_Y {
  background: white;
  border-radius: 10px;
  width: calc(75% - 10px);
  margin: 0 10px 0 0;
}

.Competitors_tableContainer__3iUeF {
  height: auto;
}

.Competitors_loadingWrapper__P98Tg {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.Keywords_wrapper__3mJWA {
  display: flex;
  flex-flow: column;
  margin: 20px;
}

.Keywords_contentContainer__2QDZ6 {
  display: flex;
  flex-flow: row wrap;
  height: 300px;
  margin-bottom: 20px;
  min-height: 300px;
}

.Keywords_chartContainer__1wlih {
  width: calc(75% - 10px);
}

.Keywords_tableContainer__19lBZ {
  height: auto;
}

.Keywords_loadingWrapper__2PLNI {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.OverviewTile_wrapper__a3NZP {
  background: white;
  height: 200px;
  border-radius: 10px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  border: 1px solid transparent;
}

.OverviewTile_wrapper__a3NZP:hover {
  cursor: pointer;
  border: 1px solid darkblue;
}

.OverviewTile_title__2zkMa {
  font-size: 1.5em;
  color: darkblue;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-column: 1 / 5;
}

.OverviewTile_container__37kCd {
  width: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.OverviewTile_value__2So7j {
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.Overview_wrapper__1rTD4 {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  color: darkgrey;
}

.Overview_container__HvfTJ {
  display: grid;
  grid-gap: 20px;
  margin: auto;
}

.Overview_containerList__Te-zG {
  display: grid;
  grid-gap: 20px;
  overflow-y: auto;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-rows: repeat(auto-fill, 200px);
}

.Overview_loadingWrapper__vWOna {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.SettingsView_container__38JpB {
  margin: 12px;
}

.SponsorshipsView_container__2vIHj {
  margin: 12px;
}

.TodosView_container__6XnWr {
  margin: 12px;
}

.Activity_emphasized__3ipns {
  font-weight: bold;
}

.ProfilePicture_content__2RqZu {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ProfilePicture_profilePicture__gNxMD {
  box-shadow: 0 0 12px lightgray;
  border-radius: 50%;
  max-height: 64px;
  max-width: 64px;
  margin: 20px 60px;
  padding: 20px;
}

.EmailVerification_verified__2007s {
  color: seagreen;
}

.EmailVerification_notVerified__6QrPT {
  color: crimson;
}

.Ban_authorized__1uxH8 {
  color: seagreen;
}

.Ban_unauthorized__3qJj4 {
  color: crimson;
}

.RunAudit_error__1Pzft {
  color: red;
  font-size: 0.96em;
  text-align: center;
}

.UserView_defaultWrapper__1ymtf {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.UserView_wrapper__27qcX {
  background-color: whitesmoke;
  min-height: 100vh;
}

.UserView_container__2lMqj {
  padding: 1rem;
}

.UserView_gridItem__3cnC9 {
  min-width: 360px;
  max-width: 360px;
}

.WordingView_container__3Reol {
  margin: 12px;
}

.NavItem_active__1ITa- {
  background: #eaeaea !important;
}

.ErrorBoundary_container__3bvNq {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12vh;
}

.ErrorBoundary_container__3bvNq p {
  margin-top: 0;
}

.ErrorBoundary_container__3bvNq button {
  margin: auto 12px;
}

.NotFound_container__1e_Lt {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 12px;
}

.App_wrapper__2o0iK {
  display: flex;
}

.App_content__1dlDM {
  width: 100%;
}

