.label {
  padding: 1px 6px;
  border-radius: 3px;
  font-size: 12px;
  min-width: 60px;
  text-align: center;
}

.red {
  background-color: red;
  color: whitesmoke;
}

.orange {
  background-color: orange;
  color: whitesmoke;
}

.green {
  background-color: green;
  color: whitesmoke;
}

.black {
  background-color: grey;
  color: whitesmoke;
}

.default {
  background-color: purple;
  color: whitesmoke;
}
