.spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.spinner:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #634c72;
  border-color: #634c72 transparent #634c72 transparent;
  animation: spinner 1.2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
