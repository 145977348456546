.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 16px;
}

.wrapper > div {
  margin-bottom: 16px;
}

.tileContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
}

.dataContainer {
  width: 100%;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 12px;
  width: 100%;
}

.titleColumn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px 0 0;
}

.valueColumn {
  border-left: 1px solid lightgrey;
  padding: 0 0 0 20px;
}
