.container {
}

.container h1 {
  font-size: 3rem;
}

.container h2 {
  font-size: 2rem;
}

.header {
  margin: 1rem 2rem;
}

.published {
  margin: 3rem 2rem;
}

.configuration {
  margin: 3rem 2rem;
}

.heading {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.heading h2 {
  margin-right: 1rem;
}
