.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 12px;
}

.titleColumn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px 0 0;
}

.titleColumn > div > span {
  margin-left: 10px;
}

.valueColumn {
  border-left: 1px solid lightgrey;
  padding: 0 0 0 20px;
}

.input {
  text-decoration: underline;
  color: darkgrey;
}

.input:hover {
  cursor: pointer;
  color: darkblue;
}
