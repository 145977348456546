.tile {
  grid-column: 1 / 3;
}

.header {
  font-weight: bold;
  padding: 0 0 6px 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper > div {
  align-items: center;
  border-bottom: 1px solid lightgrey;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.wrapper > div > div {
  flex-basis: 0;
  flex-grow: 1;
}

.wrapper input {
  max-width: 60px;
}

.enabled > div > p,
.disabled > div > p {
  margin: 12px auto;
}

.resetContainer {
  display: inline;
  color: lightgrey;
  margin-left: 12px;
}

.resettable {
  color: black !important;
}

.enabled:hover {
  background: whitesmoke;
}

.disabled {
  color: lightgrey;
}
