.wrapper {
  width: 100%;
  margin-bottom: 20px;
}

.topContainer {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.rightContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.rightContainer > div {
  margin: auto 12px;
}

.metricsContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}
